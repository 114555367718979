import React, { useEffect, useState } from "react"
import { Container, Row, Col } from "react-grid-system"
import { graphql } from "gatsby"
// import Img from 'gatsby-image';
import Img from "gatsby-image/withIEPolyfill"
import styled from "styled-components"
import Layout from "src/components/core/layout/layout"
import PhotoTile from "src/components/photos/photo-tile"
import SEO from 'src/components/seo/SEO';

const PhotosWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  @media screen and (min-width: 576px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
  width: 100%;
  margin: 0;
  padding: 0;
`

const PhotoWrapper = styled.div`
  flex-basis: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0.1rem;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  @media screen and (min-width: 576px) {
    flex-basis: ${props => props.width}%;
  }
`

export const query = graphql`
  query ProjectBySlug($uid: String!) {
    prismicProject(uid: { eq: $uid }) {
      id
      uid
      prismicId
      data {
        name {
          text
        }
        gallery {
          width
          model {
            text
          }
          photo {
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          photo_date(difference: "now")
        }
      }
    }
  }
`

const comparePhotos = (a, b) => {
  return parseInt(a.photo_date, 10) - parseInt(b.photo_date, 10)
}

const Project = ({ data }) => {
  const name = data.prismicProject.data.name
    ? data.prismicProject.data.name.text
    : null
  const gallery = data.prismicProject.data.gallery
  gallery.sort(comparePhotos)
  return (
      <>
      <SEO  title={name} image={gallery[0].photo.fluid.src}/>
    <Layout>
      <PhotosWrapper>
        {gallery.map(photo =>
          photo.photo ? (
            <PhotoWrapper width={photo.width ? photo.width : null}>
              <Img
                objectFit="contain"
                fluid={photo.photo ? photo.photo.fluid : null}
              />
            </PhotoWrapper>
          ) : null
        )}
      </PhotosWrapper>
    </Layout>
    </>
  )
}

export default Project
